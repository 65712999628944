<template>
	<div class="hx-zhengce">
		<div style="width: 80%;margin: 0px auto;background-color: white;margin-top: 15.8rem;padding: 10px;text-align: left;">
			<h2 align="center">隐私条款</h2>
			<div align="left" style="font-size: 18px;">提示条款：</div>
			<p>泰克莱恩非常重视您及您所提供的个人信息。我们将严格按照中华人民共和国相关法律法规的要求以及本协议的规定，数据处理您及您所提供的个人信息。</p>
			<p>本协议适用于山东泰克莱恩医疗科技有限公司（注册地址：济南市高新区大正路1777号生命科学城）及其关联公司（以下简称“泰克莱恩”或“我们”）提供的所有产品和/或服务，您访问或使用本系统提供的产品和/或服务，亦均适用本协议。</p>
			<p>因此，在您访问或使用本系统或泰克莱恩提供的产品和/或服务前，请仔细阅读并充分理解本协议内容，特别是以加黑加粗形式提示您注意的条款。只要您点击“确认”按钮或勾选“同意”，即表示您已充分理解并同意本协议。如您对本协议约定或内容有任何疑问、意见或建议，烦请通过本协议底部提供的联系方式联系我们，我们非常愿意为您提供可行的协助。</p>
			<p>第一部分 定义</p>
			<p>
			1、 个人信息：是指与已识别或可识别的自然人（信息主体）相关的任何数据；可识别的自然人是指尤其通过姓名、定位数据、网络标识符等标识符，或通过特定的身体、心理、基因、精神状态、经济、文化、社会等方面个人属性能够被直接或间接识别的自然人。
			2、 数据处理：是指对个人数据进行的任何操作或者一系列操作，无论其是否通过自动化手段进行，如数据收集、记录、组织、建构存储、改编或修改，恢复、查询、使用、通过传播、分发方式进行披露或者其他使个人数据可被他人获得、排列或组合、限制、清除或销毁操作。
			3、 关联公司：是指TECHLION直接或间接控制的任何其他实体，包括但不限于: 山东泰克莱恩医疗器械科技有限公司及其各分公司、杭州泰克莱恩医疗科技有限公司、北京泰克莱恩医疗科技有限公司等。
			</p>
			<p>第二部分 隐私条款</p>
			<p>本协议将帮助您了解以下内容：</p>
			<p>一、 我们如何收集和使用您及您所提供的个人信息</p>
			<p>二、 我们如何共享、公开披露您及您所提供的个人信息</p>
			<p>三、 我们如何存储和保护您及您所提供的个人信息安全</p>
			<p>四、 您应如何管理您及您所提供的个人信息</p>
			<p>五、 我们如何保护儿童的个人信息</p>
			<p>六、 您及您所提供的个人信息如何在全球范围转移</p>
			<p>七、 我们如何修订和更新本协议</p>
			<p>八、 如何联系我们</p>
			<p>一、 我们如何收集和使用您及您所提供的个人信息</p>
			<p>我们可能会从多种渠道获取您的个人信息或您提供的他人的个人信息。您可在我们的系统、通过我们的社交媒体页面及APP或在参加我们的某个活动向我们提供您或他人的个人信息。当您访问我们的系统、我们的社交媒体页面或使用我们的APP的服务功能时，我们还会通过自动方式收集有关您的设备或使用情况的某些信息，包括使用Cookies, Web服务器日志和网络信标等技术。我们也可能从其他渠道间接收集您的个人信息。
			</p>
			<p>1、 个人信息收集</p>
			<p>（1） 您向我们主动提供的您及他人的个人信息</p>
			<p>系统账号开立及相关服务 您作为医生、医生助理/咨询师或医学专业学生，在泰克莱恩系统开立账户，需向我们提供您的个人信息，包括但不限于： 您的姓名、头像、电话、联系邮箱、医师执业证书及其他相关资质证书、您所属诊所或医院的名称、地址、电话、联系邮箱、银行账号及其他基于泰克莱恩合理要求提供的信息。
			系统产品和/或服务获取 您作为账户使用人，为获取泰克莱恩相应产品和/或服务过程，将向我们提供 （未）经处理的患者个人信息，包括但不限于：患者的姓名、性别、出生年月日、照片、病例信息，包括：医生诊疗信息、患者的牙齿、面部、全身照片、X光照片、口扫数据及其他基于泰克莱恩合理要求提供的病例信息。我们理解，您是基于为患者提供泰克莱恩产品和/或服务的目的而向我们提供前述患者个人信息，且您有权并具有符合中华人民共和国法律规定的资格使用、并向我们提供、授权我们数据处理前述患者个人信息。进一步，我们理解，您在向我们提供以上患者个人信息之前，已经与患者签署了相关的知情同意书，授权您向我们提供并管理患者的个人信息，且允许我们按本协议所述方式数据处理患者的个人信息。您在收集年满14周岁的未成年人的患者数据前，应征得未成年人及其监护人的明示同意；不满14周岁的，应征得其监护人的明示同意。如您未能获得上述同意，则您不得收集相关患者个人信息。
			产品和/或服务咨询、评价、反馈 您可对我们的产品和/或服务进行咨询、评价、建议、反馈，我们将收集您的这些咨询、评价、建议、反馈内容。
			参与活动 我们可能会邀请您参与填写我们的调研问卷，您需要提供包括：您的姓名、职业、职级等信息。同时，您也可以预约我们举办的各类线上、线下活动，基于此目的，我们将收集您的姓名、手机号码、备注内容、课件内容、预约日期、以及参与时间等。
			</p>
			<p>（2） 您使用我们产品和/或服务过程中，泰克莱恩收集的个人信息
			为保障泰克莱恩系统的安全运行，为您提供方便、可靠、可信赖的产品/服务及使用环境，我们可能会收集您的操作、使用日志、网络及设备信息（包括但不限于设备型号、设备识别码、操作系统、IP地址、运营商等），以及基于您的移动设备系统权限收集的个人信息（包括但不限于基于定位权限的服务推荐功能、基于相机权限的照片上传功能等）。您开启这些权限即代表授权我们可以收集和使用这些个人信息来实现上述的功能，关闭权限即代表取消了这些授权，则我们将不再继续收集和使用这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于授权所进行的个人信息的处理。同时，为了预防恶意程序及安全运营所必需，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、总体安装使用情况与频率、应用崩溃情况、应用来源、性能数据等。
			</p>
			<p>（3） 线下收集的个人信息
			当您在线下使用我们提供的产品和/或服务、与我们进行互动与沟通时，我们可能收集、使用的与您或您患者使用我们产品及服务相关的信息，包括但不限于：姓名、年龄、职业、生日、工作经历、教育经历等。
			 </p>
			<p>（4） 泰克莱恩通过第三方间接收集的个人信息
			为了给您提供更专业、更优质、更加个性化的产品和/或服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我们的关联公司、合作伙伴会依据中华人民共和国法律的规定或与您的约定或征得您同意的前提下，向我们分享具有合法来源的个人信息。您确认我们有权获得您所提供的个人信息，并在您授权的范围内处理您所提供的个人信息。
			您有义务提供您和您患者真实、有效的个人信息，个人信息发生变化的应及时更新，以便我们核实您的身份并提供相关产品和/或服务。您如提交他人的个人信息的，应当取得他人合法授权。
			</p>
			<p>2、 个人信息使用目的</p>
			<p>您向泰克莱恩提供您的个人信息，即表示您同意泰克莱恩可以单独或结合已有信息将这些个人信息用于以下目的：</p>
			<p>（1） 允许您使用、购买、订阅泰克莱恩产品和服务，泰克莱恩可能会在产品和资料标签上列明您和/或患者的姓名、地址、电话及其他联系信息；</p>
			<p>（2） 为您提供关于我们的产品、服务、新闻和活动的相关信息；</p>
			<p>（3） 将您或您所在医疗机构的信息提供给患者并允许他们联系您；</p>
			<p>（4） 邀请您参加我们的调研问卷；</p>
			<p>（5） 分析我们的产品和服务情况，开发新的产品和服务，以及定制化我们的产品、服务和其他我们所提供的信息；</p>
			<p>（6） 帮您建立和管理泰克莱恩患者信息和档案，并根据您制定的治疗方案生产、定制产品和服务；</p>
			<p>（7） 为了解决我们系统和服务的故障问题；</p>
			<p>（8） 内部审计、数据分析和研究，改善我们的产品或服务；</p>
			<p>（9） 为合并、收购或资产出售等交易的需要，我们可能会将您所提供的个人信息进行转让，在转移您所提供的个人信息前，我们会给予您适当通知，并确保您所提供的个人信息在转让后得到与本协议相当的保护；</p>
			<p>（10） 用于法律法规、法院命令或其他法律程序、或有权政府机关要求的用途；</p>
			<p>（11） 其他合法的目的。</p>
			<p>在收集您所提供的个人信息后，我们将通过技术手段对个人信息进行去标识化处理，去标识化处理的个人信息在不借助额外信息的前提下将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您所提供的个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。我们会根据本协议的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。若我们将您的个人信息用于本协议未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
			<p>3、 我们如何使用Cookie</p>
			<p>（1） 为确保本系统正常运转并使您获得更轻松的访问体验和推广服务，我们会在您的计算机或移动设备上存储Cookies，借助于 Cookies，我们将简化您重复的登录步骤、存储您的浏览偏好等数据；</p>
			<p>（2） 您可根据自己的偏好管理或删除Cookies。您可以通过修改浏览器设置的方式拒绝接受Cookies，您也可以清除移动设备内保存的所有Cookies。但是如您这么做，您可能需要在每一次访问本系统时重复登录步骤、更改用户设置等，会在某种程度上影响您对本系统的使用。</p>
			<p>二、 我们如何共享、公开披露您及您所提供的个人信息</p>
			<p>1、 对于您提供的个人信息，我们将根据中华人民共和国相关法律法规进行保密并严格保管，不会将这些信息以任何方式提供或展示给任何第三方，但下述情况除外：</p>
			<p>（1） 事先获得您明确的同意或授权，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意；</p>
			<p>（2） 当司法机关或行政机关依照法定程序和法定职权要求本系统披露个人信息时，我们将依法提供相关资料。对于此情况下的任何披露，我们将被予以免责；</p>
			<p>（3） 对于任何由黑客攻击、计算机病毒侵入或发作、或政府管制而造成的暂时性关闭等影响系统正常运营的不可抗力事件所造成的个人信息的泄露、丢失、被盗用或被篡改等，我们均被予以免责；</p>
			<p>（4） 对于您将个人密码告知他人或与他人共享注册帐号所导致的任何个人信息泄露、丢失、被盗用或被篡改等，我们不负任何责任；</p>
			<p>（5） 对于与本系统链接的任何其它系统的个人信息泄露，丢失、被盗用或被篡改等事件，我们不负任何责任。</p>
			<p>2、 您向我们提供个人信息，即表示您同意泰克莱恩可以为本协议规定的目的将您所提供的个人信息在如下情况下向第三方分享：
			<p>（1） 我们可能会将您所提供的个人信息与我们的关联公司共享。但我们只会在必要的情况下共享必要的个人信息，且受本协议中所声明目的的约束。我们的关联公司如要改变个人信息的处理目的，将再次征求您的授权同意，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意；<p>
			<p>（2） 我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息、位置信息以及其他相关个人信息等，以保障为您提供的产品和服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品和服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：</p>
			<p>商品或技术服务的供应商 我们可能会将您所提供的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。</p>
			<p>与我们联合进行推广的合作伙伴 为了增进您浏览使用的本系统的体验，使您及时获知有关产品和/或服务的资讯，有时我们会委托其他企业为我们的产品和服务提供联合的推广。我们可能会使用您所提供的个人信息以及您所提供的非个人信息与我们联合进行推广的合作伙伴共享，为此，我们将另行告知您并取得您的同意，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意。</p>
			<p>第三方组件 为了更好地向您提供产品和服务，我们可能会将您的个人信息分享给在系统、APP中内嵌的第三方组件。 详情请点击此处</p>
			<p>您同意的其他目的 为了实现您不定时同意的目的，包括完成收集个人信息时说明的任何其他目的（例如：我们可能将所收集的信息分享给您所在的医疗机构或本系统平台其他使用者）而分享；以及</p>
			<p>其他 根据法律法规、法院命令或其他法律程序、或有权政府机关的要求，向法院或政府机关提供您所提供的个人信息。</p>
			 <p>对泰克莱恩与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本协议以及其他任何相关的保密和安全措施来处理个人信息。</p>
			<p>3、 我们不会将您所提供的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
			<p>（1） 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您所提供的个人信息，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意；</p>
			<p>（2） 在我们发生合并、收购或破产清算等情形时，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意。</p>
			<p>4、 我们仅会在以下情况下，公开披露您所提供的个人信息：</p>
			<p>（1） 获得您明确同意或基于您的主动选择，我们可能会公开披露您所提供的个人信息，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意；</p>
			<p>（2） 如果我们确定您出现违反法律法规或严重违反泰克莱恩相关协议规则的情况，或为保护泰克莱恩及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或泰克莱恩相关协议规则征得您同意的情况下披露关于您的个人信息，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意。</p>
			<p>三、 我们如何存储和保护您及您所提供的个人信息安全</p>
			<p>1、 根据中华人民共和国有关法律法规的规定，我们会对网络日志信息至少保存6个月。对于您及您所提供的其他个人信息，我们仅在为您提供本协议所述之目的所必需的最短期间内保留，法律法规另有规定或者您另行授权同意的除外。超出上述存储期限后，我们将对您及您所提供的个人信息进行删除或匿名化处理。</p>
			<p>2、 我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：（1）法律法规有明确规定；或（2）获得您明确的授权，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意。</p>
			<p>3、 您使用我们产品和/或服务期间，我们将根据业务需要，在提供产品和服务目的所需的最短期间内持续为您保存您所提供的个人信息，除非您自主取消授权、删除这些信息或者注销账户；</p>
			<p>4、 我们会采取符合业界标准的、合理可行的数据安全保护措施以保管并保障您所提供的个人信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们通过加密传输保障您在线提交信息的安全性；作为后台的存储系统与前台的用户信息收集系统在物理上进行分离，不共用同一台服务器；定期对安全边界的网络设备、安全设备进行安全评估和审核，及时修补漏洞，杜绝弱口令；定期进行系统和代码级别的漏洞扫描，及时修补发现的安全漏洞及配置不符合项；所有访问个人电子信息的运维人员需经过采用双因子认证的堡垒机才可操作，完整记录所有操作行为；</p>
			<p>5、 我们设立了信息安全管理小组，并建立了详细的系统安全管理制度，并且，我们已通过了国家针对数据安全的相关认证；</p>
			<p>6、 我们会采取合理可行的措施，尽力避免收集无关的个人信息；</p>
			<p>7、 互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您和您患者的个人信息安全。</p>
			<p>8、 我们制定了网络安全事件报告和处置管理制度，若不幸发生个人信息安全事件，我们将按照报告、响应、事后分析、整改四步处理，并按照法律法规向您告知具体情况并与您及时沟通补救措施。同时，我们还将按照监管部门要求上报个人信息安全事件的处置情况。对于您所提供的患者个人信息，在您收到我们的安全事件告知之后，可能需要您进一步通知相应的个人信息主体。</p>
			<p>四、 您应如何管理您及您所提供的个人信息</p>
			<p>1、 查询、更改您及您提供的个人信息：您在注册成功后将产生用户名和密码等账户信息，您可登录账号，通过“个人中心”功能或向我们的公司邮箱发送书面请求，要求对注册邮箱、密码、地址等个人信息进行查询、更改。如果您发现账户存在安全漏洞或被非法使用，请向我们的公司邮箱发送书面请求及时通知我们并向有关部门报案。</p>
			<p>2、 删除您及您提供的个人信息、注销账户：您有权要求我们删除本系统收集的个人信息，有权要求注销账户。您可以通过编辑个人账户信息的方式（登录账号，通过“个人中心”功能）删除部分个人信息，亦可以直接通过发送电子邮件提交相关要求。您理解，在删除您及您提供的个人信息或注销账户前，我们会验证您身份信息。除法律另有规定外，用户注销的，我们将删除您及您提供的个人信息，并停止为您提供服务（包括但不限于停止登录、下单等功能，您账户内的积分等将清零等）。请注意，当您从我们的服务中删除相应信息或我们收到并同意您的删除申请后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
			<p>3、 撤回您已同意的授权：根据本协议，您可以撤回您授予本系统的相关系统权限，这需要您在您的设备中进行设置，具体设置方式可能因您的手机系统、品牌、型号等不同而不同。</p>
			<p>4、 当您取消授权、删除个人信息或注销账户后，我们将按照法律规定删除您及您提供的个人信息，或者在法律许可的情况作匿名化处理，使其保持不可被检索、访问的状态。但是，我们仍将根据法律规定存储您的部分信息。</p>
			<p>5、 请您妥善保管您的账户信息，并应对您账户信息的安全以及对通过该账户实施的行为负责。除非相关法律规定且征得泰克莱恩的同意，否则您的账户仅限您本人使用，不得以任何方式借用、转让、赠与、继承或以任何方式允许他人使用您的账户。</p>
			<p>6、 在以下情形中，我们将无法响应您的请求，且保留查封您账户的权利：</p>
			<p>（1） 与国家安全、国防安全相关；</p>
			<p>（2） 与公共安全、公共卫生、重大公共利益相关；</p>
			<p>（3） 与犯罪侦查、起诉、审判和判决执行等相关；</p>
			<p>（4） 有充分证据表明您存在主观恶意或滥用权利；</p>
			<p>（5） 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害；</p>
			<p>（6） 涉及商业秘密；</p>
			<p>（7） 中华人民共和国法律法规规定的其他情形。</p>
			<p>7、 如本系统决定停止运营，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以公告形式将停止运营通知向您送达。对已持有的个人信息将进行删除或匿名化处理。
			对于您提出的上述请求，我们一般将在15个工作日内进行处理。</p>
			<p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
			<p>五、 我们如何保护儿童的个人信息</p>
			<p>如果没有父母或监护人的同意，儿童（不满14周岁）不得创建本系统账户。若您的患者为儿童，建议您要求儿童的父母或监护人仔细阅读本协议，并在征得儿童的父母或监护人同意的前提下使用我们的产品和/或服务或向我们提供其个人信息。对于经父母或监护人同意使用我们的产品或服务而收集个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。</p>
			<p>六、 您及您所提供的个人信息如何在全球范围内转移</p>
			<p>1、 原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
			<p>2、 由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。我们仅在必要时才分享您及您提供的个人信息，且仅限于本协议所示的目的。如果任何我们的关联公司想改变所示目的，都需要另行获得您的同意，如涉及患者个人信息，则通过您或由我们自行获得患者的授权同意。</p>
			<p>3、 依照本协议，您同意上述个人信息可传输到我们关联公司实施运营的任何国家/地区，并可由我们和/或关联公司对信息进行处理。如果您及您所提供的个人信息将被分享/传输给中国境外的关联公司或受托第三方，我们将确保：（1）在外国为您及您所提供的个人数据提供充分水平的保护；及（2）严格遵守中国有关数据跨境传输方面的法律法规。</p>
			<p>本协议和依照本协议所进行的个人信息数据处理受中华人民共和国法律管辖并按其进行解释。无论我们在何处对您及您所提供的个人信息进行数据处理，我们均将采取合理措施来保护您及您所提供的个人信息的隐私。</p>
			<p>七、 我们如何修订和更新本协议</p>
			<p>1、 我们可能根据中华人民共和国法律法规变化及维护交易秩序、保护消费者权益等需要，不时修订、更新本协议（简称“变更事项”）并公示或者通过推送通知等方式告知您。您可以通过本系统页面底部随时查阅或下载最新版本。</p>
			<p>2、 如果您不同意变更事项，您有权通过我们披露的联系方式向我们反馈意见。如反馈意见得以采纳，我们将酌情调整变更事项。</p>
			<p>3、 如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用本系统，变更事项对您不产生效力；如您在变更事项生效后仍继续使用本系统的，则视为您同意已生效的变更事项。</p>
			<p>八、 如何联系我们</p>
			<p>如果您有任何有关本系统或本协议的问题或建议，或您希望提出有关本系统或其内容的任何投诉，请通过0531-88762550与我们联系，我们将为您进行处理。</p>

		</div>
		
		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br/>山东泰克莱恩 0513-88762550</div>
	</div>
</template>

<script>
	
	export default {
		data: function() {
			return {
				

			}
		},
		methods: {
			

		},
		mounted: function() {

		},
		created: function() {
			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/hxmain/hxzhengce");
		}
	}
</script>

<style scoped="scoped">
	.hx-zhengce {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		position: relative;
		margin-bottom: 1.875rem;
	}

	
	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}
</style>
